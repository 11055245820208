import { mapState } from "vuex"

import handles_errors from "../../mixins/HandlesErrors"

const is_panel = {
    mixins: [handles_errors],
    mounted () {
        this.update_email(this.value)
        setTimeout(() => {
            const fields = this.$refs.first_field ? this.$refs.first_field.$el.getElementsByTagName("input") : []
            const field = fields.length ? fields[0] : null
            if(field && !field.classList.contains("autofilled")) field.focus() // this causes autofilled values to not be registered
        }, 300)
    },
    watch: {
        value: "update_email"
    },
    methods: {
        update_email (email) {
            this.email = email
            this.$emit("input", this.email)
        },
        loop_to_last () {
            const target = document.getElementById("last_focusable")
            if(!target) console.error("Unknown last focusable")
            else this.$nextTick(() => target.focus())
        },
        loop_to_first () {
            const target = document.getElementById("first_focusable")
            if(!target) console.error("Unknown first focusable")
            else this.$nextTick(() => target.focus())
        },
        goto (panel_id) {
            this.$emit("goto", panel_id)
        },
        dismiss () {
            if(this.dismissable) this.$emit("dismiss")
        },
        maybe_join_group () {
            const matches = window.location.search.match(/(?:\?|&)join=([^&]+)(?:&|$)/)
            if(matches) {
                this.$store.dispatch(
                    "RECORD_ACTION",
                    {
                        entity: "group_user",
                        id: matches[1],
                        action: "join",
                        method: "post",
                        data: {
                            fields: ["fieldset::group-list", "ns::user;fieldset::profile"]
                        }
                    }
                ).then(record => {
                    this.$success(this.$root.translate("You have joined the group \"{name}\"", { name: record.group_name }))
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        }
    },
    computed: {
        ...mapState(["app_context"]),
        can_signup () {
            return !!this.app_context && !!this.app_context.settings && !!this.app_context.settings.signups
        }
    },
    props: {
        value: { // email
            type: String,
            default: ""
        },
        labelLess: {
            type: Boolean,
            default: false
        },
        dismissable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            loading: false,
            email: "",
            autofill_timeout: 300
        }
    }
}

export default is_panel
