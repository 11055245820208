<template>
    <div
        v-if="show"
        :id="id"
        :class="{ active: show }"
        class="modal"
    >
        <dismiss-link
            @click="maybe_dismiss"
            class="modal-overlay"
        />
        <div
            role="dialog"
            :aria-labelledby="`modal-header-${id}`"
            aria-modal="true"
            class="modal-container"
        >
            <div
                v-if="droppable && dragging"
                ref="dazone"
                class="drop-zone-container"
            >
                <slot name="drop-zone">
                    <div class="drop-zone">

                    </div>
                </slot>
            </div>
            <div
                v-show="!droppable || !dragging"
                tabindex="0"
                @focus="loop_to_last"
            ></div>
            <div
                v-if="has_slot('title') || has_slot('subtitle')"
                v-show="!droppable || !dragging"
                class="modal-header"
            >
                <dismiss-link
                    v-if="dismissable"
                    id="first_focusable"
                    @click="dismiss"
                    class="btn btn-clear float-right"
                />
                <div v-if="has_slot('title')" class="modal-title">
                    <slot name="title"></slot>
                </div>
                <slot name="subtitle"></slot>
            </div>
            <div
                v-show="!droppable || !dragging"
                class="modal-body"
            >
                <slot></slot>
            </div>
            <div
                v-if="has_slot('footer')"
                v-show="!droppable || !dragging"
                class="modal-footer"
            >
                <slot name="footer"></slot>
            </div>
            <div
                v-show="!droppable || !dragging"
                tabindex="0"
                @focus="loop_to_first"
            ></div>
        </div>
    </div>
</template>

<script>
import is_nibnut_component from "../../mixins/IsNibnutComponent"

import DismissLink from "./DismissLink"

const can_drag_and_drop = () => {
    var div = document.createElement("div")
    return (("draggable" in div) || (("ondragstart" in div) && ("ondrop" in div))) && ("FormData" in window) && ("FileReader" in window)
}
const event_prevent_and_stop = event => {
    event.preventDefault()
    event.stopPropagation()
}

export default {
    name: "ModalDialog",
    mixins: [is_nibnut_component],
    components: {
        DismissLink
    },
    mounted () {
        this.toggle_event_listeners()
    },
    beforeDestroy () {
        if(this.show) this.dismiss()
    },
    watch: {
        show: "toggle_event_listeners"
    },
    methods: {
        toggle_event_listeners () {
            const escaped = event => {
                if((event.keyCode === 27) && this.show && this.dismissable) this.dismiss()
            }
            const dnd_dud_events = ["drag", "dragstart", "dragend", "dragover"]
            if(this.show) {
                document.addEventListener("keydown", escaped)

                // inspired by https://serversideup.net/drag-and-drop-file-uploads-with-vuejs-and-axios/
                if(this.droppable && can_drag_and_drop()) {
                    this.$nextTick(() => {
                        dnd_dud_events.forEach(event_name => {
                            this.$el.addEventListener(event_name, event_prevent_and_stop)
                        })

                        this.$el.addEventListener("dragenter", this.drag_enter_handler)
                        this.$el.addEventListener("dragleave", this.drag_leave_handler)
                        this.$el.addEventListener("drop", this.drop_handler)
                    })
                }
            } else {
                document.removeEventListener("keydown", escaped)

                if(this.droppable && can_drag_and_drop()) {
                    dnd_dud_events.forEach(event_name => {
                        this.$el.removeEventListener(event_name, event_prevent_and_stop)
                    })

                    this.$el.removeEventListener("dragenter", this.drag_enter_handler)
                    this.$el.removeEventListener("dragleave", this.drag_leave_handler)
                    this.$el.removeEventListener("drop", this.drop_handler)
                }
            }
        },
        loop_to_last () {
            const target = document.getElementById("last_focusable")
            if(!target) console.error("Unknown last focusable")
            else this.$nextTick(() => target.focus())
        },
        loop_to_first () {
            const target = document.getElementById("first_focusable")
            if(!target) console.error("Unknown first focusable")
            else this.$nextTick(() => target.focus())
        },
        drag_enter_handler (event) {
            if(!this.droppable) return
            event_prevent_and_stop(event)
            this.dragging = true
        },
        drag_leave_handler (event) {
            if(!this.droppable) return
            event_prevent_and_stop(event)
            const element = event.fromElement ? event.fromElement.closest(".drop-zone-container") : null
            if(element !== this.$refs.dazone) this.dragging = false
        },
        drop_handler (event) {
            if(!this.droppable) return
            event_prevent_and_stop(event)
            if(!!event.dataTransfer.files && !!event.dataTransfer.files.length) this.$emit("upload", event.dataTransfer.files)
            this.dragging = false
        },
        dismiss () {
            this.$emit("update:show", false)
        },
        maybe_dismiss () {
            if(this.dismissable) this.dismiss()
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        show: {
            type: Boolean,
            default: false
        },
        dismissable: {
            type: Boolean,
            default: true
        },
        droppable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            dragging: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.modal {
    .drop-zone-container,
    .drop-zone {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .drop-zone {
        border: $control-padding-x dashed $primary-color;
        background-color: rgba($primary-color, 0.3);
    }
}
</style>
