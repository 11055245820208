<template>
    <div>
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <div class="modal-header">
            <dismiss-link
                v-if="dismissable"
                id="first_focusable"
                @click="dismiss"
                class="btn btn-clear float-right"
            />
            <div class="modal-title h5">
                {{ $root.translate("Reset Password") }}
            </div>
        </div>
        <div class="modal-body">
            <base-form
                :has-required-fields="true"
                @submit.prevent.stop="reset"
            >
                <form-input
                    :id="dismissable ? 'email' : 'first_focusable'"
                    ref="first_field"
                    v-model="email"
                    name="email"
                    type="email"
                    :error="has_error('email')"
                    :required="true"
                    @input="update_email"
                >
                    <template v-slot:label v-if="!labelLess">{{ $root.translate("Email") }}</template>
                </form-input>
                <password-editor
                    id="password"
                    name="password"
                    v-model="password"
                    :error="has_error('password')"
                    :required="true"
                >
                    <template v-slot:label v-if="!labelLess">{{ $root.translate("New Password") }}</template>
                </password-editor>

                <div class="text-center mt-8">
                    <feedback-button
                        type="submit"
                        :status="feedback_status"
                        :waiting="loading"
                        :single-use="false"
                        color="primary"
                    >
                        <template v-slot:message>
                            <div
                                :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                            >
                                {{ feedback_message }}
                            </div>
                        </template>
                        {{ $root.translate("Reset my password") }}
                    </feedback-button>
                </div>
            </base-form>
        </div>
        <div class="modal-footer">
            <default-button
                id="last_focusable"
                flavor="link"
                size="sm"
                @click.prevent="cancel"
            >
                {{ $root.translate("Cancel and login instead") }}
            </default-button>
        </div>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import is_panel from "./IsPanel"

import { DismissLink, BaseForm, FormInput, PasswordEditor, DefaultButton, FeedbackButton } from "../../components"

export default {
    mixins: [is_panel],
    components: {
        DismissLink,
        BaseForm,
        FormInput,
        PasswordEditor,
        DefaultButton,
        FeedbackButton
    },
    methods: {
        reset () {
            setTimeout(() => {
                this.loading = true
                this.clear_all_errors()
                this.$store.dispatch("RESET_PASSWORD", {
                    token: this.$route.query["password-reset"],
                    email: this.email,
                    password: encodeURIComponent(this.password)
                }).then(() => {
                    this.$emit("input", "")
                    this.password = ""
                    this.cancel()
                }).catch(error => {
                    this.receive_error(error)
                }).then(() => {
                    this.loading = false
                })
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        },
        cancel () {
            window.location.search = ""
        }
    },
    data () {
        return {
            password: ""
        }
    }
}
</script>
