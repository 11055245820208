<template>
    <input-group
        glyph="search"
        glyph-position="right"
        class="data-table-search-box"
    >
        <input
            ref="field"
            :id="identifier"
            type="text"
            :name="name"
            :value="value"
            @focus="autoselect"
            @keydown="keydown"
            class="form-input"
        />
    </input-group>
</template>

<script>
import debounce from "lodash/debounce"

import is_nibnut_component from "../../mixins/IsNibnutComponent"

import InputGroup from "../Inputs/InputGroup"

export default {
    mixins: [is_nibnut_component],
    components: {
        InputGroup
    },
    methods: {
        autoselect (event) {
            event.target.select()
        },
        keydown: debounce(function (event) {
            this.$emit("input", event.target.value)
        }, 300)
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        value: {
            type: String
        }
    },
    data () {
        return {
            name: "search"
        }
    }
}
</script>
