<template>
    <div
        :id="id"
        :class="{ [`nibnut-editor-${size}`]: true }"
        class="nibnut-editor"
    >
        <div v-if="!!editor">
            <div
                class="nibnut-editor-toolbar"
            >
                <button
                    v-for="level in headers"
                    :key = level
                    type="button"
                    :class="{ 'btn-primary': editor.isActive({ heading: level }), 'btn-link': !editor.isActive({ heading: level }) }"
                    @click="editor.chain().focus().toggleHeading({ level }).run()"
                    class="btn btn-sm"
                >
                    {{ `H${level}` }}
                </button>

                <button
                    v-if="styles"
                    type="button"
                    :class="{ 'btn-primary': editor.isActive('bold'), 'btn-link': !editor.isActive('bold') }"
                    @click="editor.chain().focus().toggleBold().run()"
                    class="btn btn-sm"
                >
                    <open-icon glyph="bold" :title="$root.translate('Bold')" />
                </button>
                <button
                    v-if="styles"
                    type="button"
                    :class="{ 'btn-primary': editor.isActive('italic'), 'btn-link': !editor.isActive('italic') }"
                    @click="editor.chain().focus().toggleItalic().run()"
                    class="btn btn-sm"
                >
                    <open-icon glyph="italic" :title="$root.translate('Italic')" />
                </button>
                <button
                    v-if="styles"
                    type="button"
                    :class="{ 'btn-primary': editor.isActive('underline'), 'btn-link': !editor.isActive('underline') }"
                    @click="editor.chain().focus().toggleUnderline().run()"
                    class="btn btn-sm"
                >
                    <open-icon glyph="underline" :title="$root.translate('Underline')" />
                </button>
                <button
                    v-if="styles"
                    type="button"
                    :class="{ 'btn-primary': editor.isActive('strike'), 'btn-link': !editor.isActive('strike') }"
                    @click="editor.chain().focus().toggleStrike().run()"
                    class="btn btn-sm mr-3"
                >
                    <open-icon glyph="strikethrough" :title="$root.translate('Strikethrough')" />
                </button>

                <button
                    v-if="links"
                    type="button"
                    :class="{ 'btn-primary': editor.isActive('link'), 'btn-link': !editor.isActive('link') }"
                    @click="link_edit"
                    class="btn btn-sm mr-3"
                >
                    <open-icon glyph="link" :title="$root.translate('Link')" />
                </button>

                <button
                    v-if="!lists"
                    type="button"
                    :class="{ 'btn-primary': editor.isActive('bulletList'), 'btn-link': !editor.isActive('bulletList') }"
                    @click="editor.chain().focus().toggleBulletList().run()"
                    class="btn btn-sm"
                >
                    <open-icon glyph="list" :title="$root.translate('Bullet List')" />
                </button>

                <button
                    v-if="!quotes"
                    type="button"
                    :class="{ 'btn-primary': editor.isActive('blockquote'), 'btn-link': !editor.isActive('blockquote') }"
                    @click="editor.chain().focus().toggleBlockquote().run()"
                    class="btn btn-sm"
                >
                    <open-icon glyph="quote-right" :title="$root.translate('Block Quote')" />
                </button>
            </div>
            <editor-content
                :editor="editor"
                class="nibnut-editor-content"
            />
            <!--
            <editor-menu-bar
                :editor="editor"
            >
                <div
                    slot-scope="{ commands, isActive, getMarkAttrs }"
                    class="nibnut-editor-toolbar"
                >
                    <button
                        v-for="level in headers"
                        :key = level
                        type="button"
                        :class="{ 'btn-primary': isActive.heading({ level }), 'btn-link': !isActive.heading({ level }) }"
                        @click="commands.toggleHeading({ level })"
                        class="btn btn-sm"
                    >
                        {{ `H${level}` }}
                    </button>

                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.bold(), 'btn-link': !isActive.bold() }"
                        @click="commands.toggleBold"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="bold" :title="$root.translate('Bold')" />
                    </button>
                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.italic(), 'btn-link': !isActive.italic() }"
                        @click="commands.toggleItalic"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="italic" :title="$root.translate('Italic')" />
                    </button>
                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.underline(), 'btn-link': !isActive.underline() }"
                        @click="commands.toggleUnderline"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="underline" :title="$root.translate('Underline')" />
                    </button>
                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.strike(), 'btn-link': !isActive.strike() }"
                        @click="commands.toggleStrike"
                        class="btn btn-sm mr-3"
                    >
                        <open-icon glyph="strikethrough" :title="$root.translate('Strikethrough')" />
                    </button>

                    <button
                        v-if="links"
                        type="button"
                        :class="{ 'btn-primary': isActive.link(), 'btn-link': !isActive.link() }"
                        @click="link_edit(commands.toggleLink, getMarkAttrs('link'))"
                        class="btn btn-sm mr-3"
                    >
                        <open-icon glyph="link" :title="$root.translate('Link')" />
                    </button>

                    <button
                        v-if="!lists"
                        type="button"
                        :class="{ 'btn-primary': isActive.bullet_list(), 'btn-link': !isActive.bullet_list() }"
                        @click="commands.toggleBulletList"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="list" :title="$root.translate('Bullet List')" />
                    </button>

                    <button
                        v-if="!quotes"
                        type="button"
                        :class="{ 'btn-primary': isActive.blockquote(), 'btn-link': !isActive.blockquote() }"
                        @click="commands.toggleBlockquote"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="quote-right" :title="$root.translate('Block Quote')" />
                    </button>
                </div>
            </editor-menu-bar>

            <editor-content
                :editor="editor"
                class="nibnut-editor-content"
            >
                <div slot="content">{{ value }}</div>
            </editor-content>
            //-->
        </div>

        <modal-dialog
            :id="`${id}-link-editor`"
            ref="link_editor"
            :show.sync="linking"
        >
            <div>
                <form-input
                    :id="`${id}-link-editor-url`"
                    name="link_url"
                    v-model="link_url"
                    :required="false"
                >
                    <template v-slot:label>{{ $root.translate("Url") }}</template>
                </form-input>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        class="mr-2"
                        @click.prevent="linking=false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        class="ml-2"
                        @click.prevent="link_save"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </div>
</template>

<script type="text/javascript">
import is_nibnut_component from "../../mixins/IsNibnutComponent"
import is_alpha_numerical_input from "../../mixins/IsAlphaNumericalInput"

/*
import {
    Editor,
    EditorMenuBar,
    EditorContent
} from "tiptap"
import {
    // Nodes
    Blockquote,
    HardBreak,
    Heading,

    // Marks
    Bold,
    Italic,
    Link,
    Strike,
    Underline,

    BulletList,
    ListItem
} from "tiptap-extensions"
*/
import { Editor, EditorContent } from "@tiptap/vue-2"
import Document from "@tiptap/extension-document"
import Paragraph from "@tiptap/extension-paragraph"
import Text from "@tiptap/extension-text"
import HardBreak from "@tiptap/extension-hard-break"
import Heading from "@tiptap/extension-heading"
import Blockquote from "@tiptap/extension-blockquote"
import Bold from "@tiptap/extension-bold"
import Italic from "@tiptap/extension-italic"
import Link from "@tiptap/extension-link"
import Strike from "@tiptap/extension-strike"
import Underline from "@tiptap/extension-underline"
import BulletList from "@tiptap/extension-bullet-list"
import ListItem from "@tiptap/extension-list-item"

import ModalDialog from "../ModalDialog/ModalDialog"
import FormInput from "./FormInput"
import DefaultButton from "../Buttons/DefaultButton"
import OpenIcon from "../OpenIcon"

export default {
    name: "BaseEditor",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        EditorContent,
        ModalDialog,
        FormInput,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        const extensions = [
            Document,
            Paragraph,
            Text,
            HardBreak
        ]
        if(this.headers > 0) extensions.push(Heading.configure({ maxLevel: 3 }))
        if(this.styles) {
            extensions.push(Bold)
            extensions.push(Italic)
            extensions.push(Underline)
            extensions.push(Strike)
        }
        if(this.lists) {
            extensions.push(BulletList)
            extensions.push(ListItem)
        }
        if(this.quotes) extensions.push(Blockquote)
        if(this.links) extensions.push(Link)
        this.editor = new Editor({
            extensions,
            onUpdate: () => {
                this.editor_html = this.editor.getHTML()
            },
            onBlur: () => {
                this.$emit("input", this.editor_html, this.name)
            }
        })
        if(this.editor) {
            this.editor_html = this.value
            this.editor.commands.setContent(this.value)
        }
    },
    beforeDestroy () {
        if(this.editor) {
            this.editor.destroy()
            this.editor = null
        }
    },
    watch: {
        value (new_value, old_value) {
            if(!this.bootstraped || (new_value !== old_value)) {
                this.bootstraped = true
                this.editor_html = this.value
                this.editor.commands.setContent(this.value)
            }
        }
    },
    methods: {
        link_edit () {
            this.link_url = this.editor.getAttributes("link").href
            this.linking = true
            this.$nextTick(() => {
                this.$refs.link_editor.$el.getElementsByTagName("input")[0].focus()
            })
        },
        link_save () {
            if(!this.link_url) {
                this.editor.chain().focus().extendMarkRange("link").unsetLink().run()
            } else {
                if(!this.link_url.match(/^(?:https?:)?\/\//i)) this.link_url = "https://" + this.link_url
                this.editor.chain().focus().extendMarkRange("link").setLink({ href: this.link_url }).run()
            }
            this.linking = false
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        headers: {
            type: Number,
            validator: prop => (prop >= 0) && (prop <= 6),
            default: 3
        },
        styles: {
            type: Boolean,
            default: true
        },
        links: {
            type: Boolean,
            default: true
        },
        lists: {
            type: Boolean,
            default: true
        },
        quotes: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md|lg|full)$/i),
            default: "md"
        }
    },
    data () {
        return {
            editor: null,
            editor_html: "",
            bootstraped: false,

            link_url: null,
            linking: false
        }
    }
}
</script>

<style lang="scss">
@import "../../../assets/sass/variables";

.nibnut-editor {
    & > div:first-of-type {
        background: $bg-color-light;
        background-image: none;
        border: $border-width solid $border-color-dark;
        border-radius: $border-radius;
        color: $body-font-color;
        display: block;
        font-size: $font-size;
        line-height: $line-height;
        max-width: 100%;
        position: relative;
        transition: background .2s, border .2s, box-shadow .2s, color .2s;
        width: 100%;

        .nibnut-editor-toolbar {
            padding: $control-padding-y $control-padding-x;
            background-color: $bg-color-light;
        }
        .nibnut-editor-content {
            box-shadow: 0 0.1rem 0.1rem rgba(48,55,66,.1) inset;
            overflow: auto;

            & > .ProseMirror {
                height: 100%;
                appearance: none;
                outline: none;
                padding: ($control-padding-y * 2) $control-padding-x $control-padding-y $control-padding-x;

                &:focus {
                    border-color: transparent;
                }

                & > :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.nibnut-editor-sm {
        & > div:first-of-type > .nibnut-editor-content {
            height: 2rem;
        }
    }
    &.nibnut-editor-md {
        & > div:first-of-type > .nibnut-editor-content {
            height: 3.5rem;
        }
    }
    &.nibnut-editor-lg {
        & > div:first-of-type > .nibnut-editor-content {
            height: 7rem;
        }
    }
    &.nibnut-editor-full {
        display: flex;
        flex-direction: column;

        & > div {
            flex: 0 0 auto;
        }
        & > div:last-of-type {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            & > .nibnut-editor-toolbar {
                flex: 0 0 auto;
            }
            & > .nibnut-editor-content {
                flex: 1 0 auto;
            }
        }
    }
}
</style>
