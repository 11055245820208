<template>
    <img :src="logo" :alt="alt" class="img-responsive app-logo" />
</template>

<script>
import { mapState } from "vuex"

import logo_w_caption from "@/assets/img/logo_w_caption.png"
import logo from "@/assets/img/logo.png"

export default {
    computed: {
        ...mapState(["app_context"]),
        logo () {
            if(this.withText) return logo_w_caption
            return logo
        },
        alt () {
            if(this.app_context.settings && this.app_context.settings) return this.app_context.settings.application_name
            return ""
        }
    },
    props: {
        withText: {
            type: Boolean,
            default: true
        }
    }
}
</script>
