<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <input-group
            v-bind="input_group_props"
            @click="click_icon"
        >
            <template v-slot:left_addon><slot name="left_addon"></slot></template>
            <template v-slot:right_addon><slot name="right_addon"></slot></template>
            <base-input
                ref="field"
                :id="identifier"
                :type="standardized_type"
                :name="name"
                :value="value"
                :placeholder="placeholder"
                :step="step"
                :min="min"
                :max="max"
                :maxlength="maxlength"
                :aria-describedby="!!has_slot('hint')?`${identifier}-description`:false"
                :autocomplete="autocomplete"
                :autocapitalize="autocapitalize"
                :autocorrect="autocorrect"
                :disabled="!editable"
                :required="required && touched"
                :size="size"
                :class="{ autofilled: maybeAutofilled }"
                @focus="maybe_autoselect"
                @touchstart="touchstart"
                @keyup="$emit('keyup', $event)"
                @keydown="keydown"
                @blur="blur"
                @change="changed"
            />
        </input-group>
    </form-group>
</template>

<script>
import debounce from "lodash/debounce"

import is_nibnut_component from "../../mixins/IsNibnutComponent"
import is_alpha_numerical_input from "../../mixins/IsAlphaNumericalInput"

import FormGroup from "./FormGroup"
import InputGroup from "./InputGroup"
import BaseInput from "./BaseInput"

const typed_value = (value, type, default_value = null) => {
    if(type === "float") return parseFloat(value) || default_value
    if(type === "number") return parseInt(value) || default_value
    return value
}

export default {
    name: "FormInput",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        FormGroup,
        InputGroup,
        BaseInput
    },
    watch: {
        editable: "maybe_focus_field"
    },
    methods: {
        focus_field () {
            this.touched = true
            if(this.$refs.field) {
                if(this.$refs.field.focus) this.$refs.field.focus()
                if(this.$refs.field.select) this.$refs.field.select()
            }
        },
        maybe_focus_field () {
            if(this.editable) setTimeout(this.focus_field, 150)
        },
        maybe_autoselect (event) {
            if(this.autoSelect || this.type.match(/^(float|number)$/i)) event.target.select()
        },
        keydown: debounce(function (event) {
            if(this.alphanumeric && this.iOS) this.touching = false
            if(this.live) this.$emit("input", typed_value(event.target.value), this.name)
        }, 300),
        blur (event) {
            if(this.alphaNumeric && this.iOS) this.touching = false
            const value = typed_value(event.target.value)
            if(!this.live) this.$emit("input", value, this.name)
            this.$emit("blur", value, this.name) // can be useful for autofilled values... Use this to manually set your v-model's value
        },
        click_icon () {
            this.focus_field()
        },
        changed (event) {
            if(this.type.match(/^(float|number)$/i)) {
                const value = typed_value(event.target.value)
                this.$emit("input", value, this.name)
            }
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        input_group_props () {
            return {
                glyph: this.glyph,
                "glyph-position": this.glyphPosition,
                "glyph-library": this.glyphLibrary
            }
        },
        standardized_type () {
            if(this.type === "float") return "number"
            return this.type
        },
        step () {
            if(this.type === "float") return this.floatStep
            if(this.type === "number") return 1
            return false
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        autocomplete: {
            type: String,
            default: ""
        },
        autocapitalize: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(none|sentences|words|characters)$/i),
            default: "sentences"
        },
        autocorrect: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(on|off)$/i),
            default: "on"
        },
        type: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(text|number|float|password|email|tel|hidden)$/i),
            default: "text"
        },
        glyph: {
            type: String,
            default: ""
        },
        glyphLibrary: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(las|lab)$/i),
            default: "las"
        },
        glyphPosition: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(left|right)$/i),
            default: "right"
        },
        floatStep: {
            type: Number,
            default: 0.1
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        required: {
            type: Boolean,
            required: true
        },
        min: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        maxlength: {
            type: Number,
            default: null
        },
        editable: {
            type: Boolean,
            default: true
        },
        live: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        },
        maybeAutofilled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            touched: false
        }
    }
}
</script>
